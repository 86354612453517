<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addEvent'})">
      {{ $t('auth.events') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="4" class="mb-3">
            <input-form placeholder="العنوان" label="العنوان" v-model="eventData.title" name="title" validate="required"></input-form>
          </b-col>
          <b-col md="9" class="mb-3">
<!--            :disabled="routeName === 'showProduct'"-->
            <textarea-form placeholder="الوصف" v-model="eventData.excerpt" label="وصف مختصر" name="details" validate="required"></textarea-form>
          </b-col>
          <b-col md="9" class="mb-3">
            <textarea-form  placeholder="المحتوى" v-model="eventData.content" label="التفاصيل"  name="description" validate="required"></textarea-form>
          </b-col>
          <b-col md="9">
            <label class="text-black font-size-18">تاريخ النشر</label>
            <flat-picker  v-model="eventData.publishDate" class="form-control form-date"  validate="required" name="publishDate" :config="{minDate: 'today'}" placeholder="تاريخ النشر"></flat-picker>
          </b-col>
          <b-col md="9" class="mb-3">
          <cropper-images
              label="رفع الصورة الرئيسية"
              nameOfImage="image.jpg"
              @cropper-save="saveMasterImage"
              :progressLoading="loadingLogo"
              :showProgress="false"
              :multi="false"
              :imageUrl="eventData.featured_image"
          />
          </b-col>
      </b-row>
        <b-row>
          <b-col md="9" class="d-flex justify-content-center align-items-center">
            <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.events') }}</b-button>
            <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
          </b-col>
        </b-row>
<!--                <div class="d-flex align-items-center justify-content-center ">-->
<!--                  <b-button variant="primary" class="px-5 py-3" type="submit" v-if="!loadingSubmit">اضافة {{ $t('auth.events') }}</b-button>-->
<!--                  <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>-->
<!--                </div>-->
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import eventsServices from '../services/events'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: { },
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      loadingSubmit: false,
      loadingLogo: 0,
      eventData: {
        featured_image: '',
        title: '',
        content: '',
        excerpt: '',
        publishDate: ''
      },
      img: ''
    }
  },
  methods: {
    getEventData () {
      eventsServices.getEvent(this.$route.params.id).then(res => {
        this.eventData = res.data
      })
    },
    saveMasterImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options).then(res => {
        this.eventData.featured_image = res.data.url
        this.showSuccessUploadFile()
      }).catch(err => {
        console.log(err)
      })
    },
    onSubmit () {
      if (this.$route.name === 'editEvent') {
        this.editEvent()
      } else {
        this.loadingSubmit = true
        eventsServices.addEvent({
          title: this.eventData.title,
          excerpt: this.eventData.excerpt,
          content: this.eventData.content,
          featured_image: this.eventData.featured_image,
          publish_date: this.eventData.publishDate
        }).then(res => {
          core.showSnackbar('success', 'تم اضافة ')
          this.loadingSubmit = false
          this.$router.push({ name: 'events' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    },
    editEvent () {
      eventsServices.editEvent(this.$route.params.id, {
        title: this.eventData.title,
        excerpt: this.eventData.excerpt,
        content: this.eventData.content,
        featured_image: this.eventData.featured_image,
        publish_date: this.eventData.publishDate
      }).then(() => {
        core.showSnackbar('success', 'تم تعديل بنجاح')
        this.$router.push({ name: 'events' })
      })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getEventData()
    }
  }
}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
</style>
